const manualDeliverText = `
  O adquirente do imóvel, conforme Contrato de Promessa de Compra e Venda, declara expressamente nesta data, que recebeu para uso, o referido imóvel em perfeitas condições de habitabilidade, tendo efetuado vistoria, e constatado que todas as suas instalações estão em perfeito estado de funcionamento, bem como não apresenta nenhum vício ou defeito aparente.
  Declara também que está ciente, que a partir desta data, passa a contar com as garantias que lhe são asseguradas pelo Código de Defesa do Consumidor, fixando-se em 90 (noventa) dias a responsabilidade do construtor quanto aos vícios aparentes ou de fácil constatação ou ocultos, e, em cinco anos quanto à solidez da estrutura.
  Declara ainda, que está de pleno acordo que até a data da efetiva instalação do condomínio da referida edificação, todas as despesas decorrentes com a administração, vigilância, água, limpeza, energia, manutenção de elevadores, das partes e coisas condominiais, e as com pessoal, inclusive os encargos sociais - serão rateados proporcionalmente entre os apartamentos do edifício, observando-se as disposições específicas sobre o assunto existente na Convenção de Condomínio e no instrumento particular de promessa de compra e venda da aludida unidade habitacional.
  Concorda que, para prevenir dúvidas, todas as comunicações com a Construtora e Incorporadora deverão ser sempre feitas através da plataforma fornecida, através de pedido de assistência técnica, pela que se obriga a tanto.
  Concorda com qualquer possível modificação que tenha feito no projeto, nas especificações ou no acabamento da unidade e do prédio, tendo-se como necessárias ao atendimento de aspectos técnicos; ou em decorrência de qualidade e segurança, ou ainda, por falta no mercado de materiais originalmente anunciado.
  Declara que recebeu via única do MANUAL DE USO E MANUTENÇÃO DO IMÓVEL.
  A partir do presente momento, o adquirente assume a responsabilidade pela transmissão do MANUAL DE USO E MANUTENÇÃO DO IMÓVEL em caso de alienação, dação em pagamento, cessão de direitos e/ou quaisquer outras formas de transmissão da posse e propriedade da referida unidade condominial.
  Dá, em consequência, ampla, geral, rasa e irrevogável quitação a incorporadora e construtora da edificação.
`;

module.exports = { manualDeliverText };