import React from "react";
import { Typography } from "@material-ui/core";
import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';
import moment from "moment";
const { manualDeliverText } = require('../../utils/manualDeliver');


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function ListSignedTerms({ signedTerms }) {
    const classes = useStyles();
    const termsArray = Array.isArray(signedTerms) ? signedTerms : [signedTerms];


    const StyledImage = styled.div`
    img{
        max-width: 100%;
    } `

    return (
        <>
            {termsArray.length ? (
                <>
                    {termsArray.map((s, i) => (
                        <>
                            {s.building.company_message &&(
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${i}-content`}
                                        id={`panel${i}-header`}
                                    >
                                        <Typography className={classes.heading}><b>Aviso da construtora</b></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <Typography>Conteúdo:<StyledImage dangerouslySetInnerHTML={{ __html: s.building.company_message }} /></Typography>
                                        </div>
                                    </AccordionDetails>

                                </Accordion>
                            )}
                            {s.manual_deliver_signature_date && (
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${i}-content`}
                                        id={`panel${i}-header`}
                                    >
                                        <Typography className={classes.heading}><b>Termo de recebimento do Manual do Proprietário</b></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <Typography>Data assinatura: {moment(s.manual_deliver_signature_date).format("DD/MM/YYYY")}</Typography>
                                            <Typography>Conteúdo: {manualDeliverText}</Typography>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            )}
                            {s.term_signed && (
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${i}-content`}
                                        id={`panel${i}-header`}
                                    >
                                        <Typography className={classes.heading}><b>Termo LGPD</b></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <Typography>Data assinatura: {moment(s.release_date).format("DD/MM/YYYY")}</Typography>
                                            <Typography>Conteúdo: {s.term_signed}</Typography>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                            )}
                        </>
                    ))}
                </>
            ) : (
                <></>
            )}
        </>
    );
}
